<template>
  <div id="chart" style="width: 100%">
    <v-layout justify-center>
      <h2>{{ $t('Installations in the past month') }}</h2>
    </v-layout>
    <VueApexCharts
      style="direction: ltr"
      type="line"
      height="350"
      width="100%"
      :options="chartOptions"
      :series="series"
    ></VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'FullViewReportXY',
  components: { VueApexCharts },
  props: ['categories', 'series'],
  data() {
    return {
      chartOptions: {}
    };
  },
  methods: {
    setData() {
      this.chartOptions = {
        grid: {
          show: true,
          borderColor: 'rgba(0, 0, 0, .2)',
          strokeDashArray: 3,
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        chart: {
          toolbar: {
            show: true
          }
        },
        stroke: {
          curve: 'smooth',
          width: 2
        },
        colors: [
          '#26c6da',
          '#1e88e5',
          '#2dce89',
          '#4fc3f7',
          '#DAA520',
          '#E6E6FA',
          '#ADD8E6'
        ],
        fill: {
          type: 'gradient',
          opacity: ['0.1', '0.1']
        },
        xaxis: {
          categories: this.categories,
          labels: {
            style: {
              cssClass: 'grey--text lighten-2--text fill-color'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              cssClass: 'grey--text lighten-2--text fill-color'
            },
            formatter: (val) => val.toFixed(0)
          }
        },
        markers: {
          size: 3
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
          theme: 'dark'
        },
        legend: {
          show: false
        }
      };
    }
  },
  watch: {
    categories(){
      this.setData();
    }
  },
  created() {
    this.setData();
  }
};
</script>